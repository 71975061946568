<template>
  <v-card flat>
    <v-list three-line>
      <template v-for="(item, index) in spots">
        <v-divider :key="item.spotId + '_divider'" v-if="index != 0"></v-divider>
        <v-list-item :key="item.spotId +  '_list'" @click="selectSpot(item)" :class="(item.hunted || item.qrt) ? 'dim-spot-row' : ''">
          <v-list-item-content>
            <v-list-item-title>
              <user-stats :callsign="item.activator"></user-stats>
              at <router-link :to="{ name: 'park', params: { id: item.reference } }">{{ item.reference }} {{ item.name }}</router-link>
            </v-list-item-title>
            <v-list-item-subtitle>
              "{{ item.comments }}" &mdash; <user-stats :callsign="item.spotter"></user-stats>
            </v-list-item-subtitle>
          </v-list-item-content>
            <v-list-item-action-text>
              {{ item.spotTime | dateTimeCard }}<br>
              <span v-if="item.locationDesc.includes(',')">
                {{ item.locationDesc.split(',')[0].concat(', + ', (item.locationDesc.split(',').length - 1)) }}
              </span>
              <span v-else>{{item.locationDesc }}</span>
              <br>
              {{ item.frequency }} ({{ item.mode }})
            </v-list-item-action-text>
        </v-list-item>
      </template>
    </v-list>
    <spot-form noaction ref="mobileSpotForm"></spot-form>
  </v-card>
</template>

<style scoped>
.dim-spot-row {
  background-color: var(--v-dim-base);
}
</style>

<script>
import SpotForm from '@/components/SpotForm'
import UserStats from '@/components/UserStats'

import colors from 'vuetify/lib/util/colors'

export default {
  computed: {
		spots: {
			get () {
				return this.$store.state.spots.filtered
			}
    },
    callsign: {
			get () {
				return this.$store.state.user.callsign
			}
    },
    hunted: {
      get () {
        return this.$store.state.user.hunted
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    isSpotAdmin: {
			get () {
				return this.$store.state.user.isSpotAdmin
      }
    },
    dimColor: {
      get() {
        if (this.$store.state.config.darkMode)
          return colors.grey.darken3;
        else
          return colors.grey.lighten3;
      }
    },
    normalColor: {
      get() {
        return colors.transparent;
      }
    },
    loadingSpots: {
      get() {
        return this.$store.state.spots.loading
      }
    }
  },
  components: {
    UserStats,
    SpotForm
  },
  methods: {
    selectSpot(spot) {
      if (this.isAuthenticated)
      {
        spot = JSON.parse(JSON.stringify(spot));
        this.$refs.mobileSpotForm.openDialog(spot);
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>
