<template>
<v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
    <v-btn :color="(filter && filter != options[0].value) ? 'orange' : 'default'" text tile v-bind="attrs" v-on="on">
        <b>{{name}}:</b>&nbsp;
        <v-badge inline :content="count.toString()" :color="(filter && filter != options[0].value) ? 'accent' : 'primary'" v-if="!nocount">{{filter}}</v-badge>
        <span v-else>{{filter}}</span>
        <v-icon>{{$vuetify.icons.values.mdiMenuDown}}</v-icon>
    </v-btn>
    </template>
    <v-list>
    <v-list-item-group v-model="filter">
        <v-list-item v-for="(item, i) in options" :key="i" :id="'menu-item-' + name + '-' + i" :value="item.value">
            <v-list-item-title>{{ item.value }}</v-list-item-title>
            <v-list-item-action v-if="!nocount"><v-badge inline :content="item.count.toString()"></v-badge></v-list-item-action>
        </v-list-item>
    </v-list-item-group>
    </v-list>
</v-menu>
</template>

<style scoped>
.v-btn {
  text-transform: none !important;
  padding-left: 8px;
  padding-right: 2px;
}
.v-btn b {
  font-weight: bolder;
  padding-right: 8px;
}
</style>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    count: {
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    nocount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    filter: {
      get() {
        return this.selected
      },
      set(value) {
        this.selected = value
        this.$emit('input', this.selected)
      }
    }
  },
  data () {
    return {
        selected: this.value
    }
  }
}
</script>
