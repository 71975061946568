<template>
<ul class="horizontal-list horizontal-list-container">
<li>
  <filter-drop-menu name="Period" v-model="periodFilter" :count="periodFilteredCount" :options="availablePeriods"></filter-drop-menu>
</li>
<li>
  <filter-drop-menu name="Sort" v-model="sortSelect" :options="sortOptions" nocount></filter-drop-menu>
</li>
</ul>
</template>

<style scoped>
.horizontal-list {
  list-style-type: none;
  overflow: hidden;
}
.horizontal-list li.heading {
  font-weight: bold;
}
.horizontal-list li {
  float: left;
  margin-right: 15px;
}
.horizontal-list-container {
  margin: 12px 0 12px -12px;
}
</style>

<script>
import FilterDropMenu from '@/components/FilterDropMenu'

import dayjs from 'dayjs'

export default {
  components: {
    FilterDropMenu
  },
  computed: {
    periodFilter: {
			get () {
        var period = this.$store.state.activations.period
        var selected = this.availablePeriods.filter(item => item.days == period)
				if (selected && selected.length > 0)
          return selected[0].value
        return this.availablePeriods[0].value
      },
      set (value) {
        var selected = this.availablePeriods.filter(item => item.value == value)
        if (selected && selected.length > 0)
          this.$store.commit('SET_ACTIVATIONS_PERIOD', selected[0].days);
      }
    },
    periodFilteredCount: {
      get() {
        var filter = this.periodFilter;
        if (!filter) filter = 'All'
        var active = this.availablePeriods.filter(item => item.value == filter)
        if (active == null || active.length == 0)
          return 0
        return active[0].count
      }
    },
    availablePeriods: {
      get() {
        var periods = [
          { value: 'All', days: 365 },
          { value: '30 Days', days: 30 },
          { value: '7 Days', days: 7 },
          { value: 'Today', days: 1 }
        ]

        for (var i = 0; i < periods.length; i++)
        {
          var endDate = dayjs.utc().add(periods[i].days, 'day')
          periods[i].count = this.$store.state.activations.data.filter(x => dayjs.utc(x.startDate) <= endDate).length
        }

        return periods
      }
    },
    sortSelect: {
      get () {
				return this.$store.state.activations.sort || this.sortOptions[0]
      },
      set (value) {
        this.$store.commit('SET_ACTIVATIONS_SORT', value);
      }
    }
  },
  data () {
    return {
      sortOptions: [
        { value: 'Start Date/Time' },
        { value: 'Activator' },
        { value: 'Reference' },
        { value: 'Park Name' },
        { value: 'Region' }
      ]
    }
  }
}
</script>
