<template>
  <span v-if="isAuthenticated">
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="ma-2" v-bind="attrs" v-on="on">
          <v-icon v-if="worked" color="success">{{$vuetify.icons.values.mdiCommentCheck}}</v-icon>
          <v-icon v-else-if="modes.length > 0" color="success">{{$vuetify.icons.values.mdiCommentCheckOutline}}</v-icon>
          <v-icon v-else>{{$vuetify.icons.values.mdiCommentCheckOutline}}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense v-if="modes.length > 0">
          <v-list-item><v-list-item-title>Hunted:</v-list-item-title></v-list-item>
          <v-list-item v-for="item in modes" :key="item.activator + item.reference + item.band + item.mode">
            <v-list-item-subtitle>{{ item.mode }} on {{ item.band }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
        <v-list dense v-else>
          <v-list-item>
            <v-list-item-title>Not Hunted</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </span>
</template>

<script>
export default {
  name: 'SpotWorked',
  props: {
    worked: {
      type: Boolean,
      required: true,
      default: false
    },
    activator: {
      type: String,
      required: true
    },
    reference: {
      type: String,
      required: true
    },
    band: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    hunted: {
      get() {
        return this.$store.state.spots.hunted
      }
    },
    modes: {
      get() {
        if (!this.hunted)
           return [];
        return this.hunted.filter(x => x.activator == this.activator && x.reference == this.reference);
      }
    }
  }
}
</script>