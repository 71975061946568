<template>
    <v-layout row wrap>
       <v-flex xs12>
         <v-row no-gutters>
         <v-col class="col-12 col-md-6">
           <span class="title text-xs-left primary--text">Active Spots</span>
           <span class="refresh-timer" v-if="!pause">
             <em>Data will refresh in {{ refreshCountdown }} seconds.</em>
             <v-btn v-if="enablePause" icon small @click="setPause(true)"><v-icon>{{$vuetify.icons.values.mdiPause}}</v-icon></v-btn>
           </span>
           <span class="refresh-timer" v-else>
             <em>Data refresh is <b>paused</b> for {{ resetPauseCountdown }} seconds.</em>
             <v-btn v-if="enablePause" icon small @click="setPause(false)"><v-icon>{{$vuetify.icons.values.mdiPlay}}</v-icon></v-btn>
          </span>
         </v-col>
         <v-col class="col-12 col-md-6" align="right" align-self="end" v-if="!$vuetify.breakpoint.mobile">
           <span class="refresh-timer">
              At a park now?
              <a v-if="!isAuthenticated" @click="doSignIn"><b>Login to add your spot!</b></a>
              <spot-form v-else :pspotter="callsign" small requireComment></spot-form>
           </span>
         </v-col>
         </v-row>
         <v-row no-gutters>
           <v-col><spot-filter /></v-col>
         </v-row>
         <v-row no-gutters>
         <v-col>
         <spot-list v-if="mobileView" />
         <spot-table hide-actions v-if="$vuetify.breakpoint.smAndUp && tableView && !mobileView"/>
         <spot-card v-if="!mobileView && ($vuetify.breakpoint.xsOnly || ! tableView)"/>
         </v-col>
         </v-row>
       </v-flex>
    </v-layout>
</template>

<script>
import { Auth } from 'aws-amplify'

import { mapState } from 'vuex'

import SpotTable from '@/components/SpotTable'
import SpotCard from '@/components/SpotCard'
import SpotList from '@/components/SpotList'
import SpotFilter from '@/components/SpotFilter'
import SpotForm from '@/components/SpotForm'

export default {
  mounted () {
    this.$store.dispatch('getSpots')
  },
  created () {
    this.startTimer();
  },
  computed: mapState({
    spots: state => state.spots.spots,
    loadingSpots: state => state.spots.loading,
    tableView: state => state.config.tableView,
    enablePause: state => state.global.enablePause,
    isAuthenticated: state => state.user.isAuthenticated,
    callsign: state => state.user.callsign
  }),
  components: {
    SpotTable,
    SpotCard,
    SpotList,
    SpotFilter,
    SpotForm
  },
  data () {
    return {
      pause: false,
      refreshCountdown: 60,
      refreshTimer: null,
      refreshHuntedCountdown: 300,
      resetHuntedCountdown: 10,
      resetPauseCountdown: 300,
      search: '',
      currentUtcDay: this.$dayjs.utc().format('YYYY-MM-DD'),
      headers: [
        { text: 'Time', value: 'activeCallsign', align: 'left', sortable: false },
        { text: 'Activator', value: 'activator', align: 'left' },
        { text: 'Frequency', value: 'frequencies', align: 'left', sortable: false },
        { text: 'Reference', value: 'reference', align: 'left', sortable: false },
        { text: 'State', value: 'state', align: 'left', sortable: false },
        { text: 'Spotter', value: 'spotter', align: 'left', sortable: false },
        { text: 'Comments', value: 'comments', align: 'left', sortable: false },
      ],
      mobileView: false
    }
  },
  methods: {
    doSignIn: function () {
      Auth.federatedSignIn();
    },

    setPause(value) {
      this.pause = value;
      if (this.pause)
        this.resetPauseCountdown = 300;
      else
        this.refreshCountdown = 0;
    },

    startTimer() {
      if (this.refreshTimer == null) {
        this.refreshTimer = setInterval(() => { this.onTick(); }, 1000);
      }
    },

    onTick() {
      this.refreshCountdown--;
      this.refreshHuntedCountdown--;
      this.resetHuntedCountdown--;

      if (this.pause)
        this.resetPauseCountdown--;

      if (this.resetPauseCountdown <= 0)
      {
        this.resetPauseCountdown = 300;
        this.pause = false;
      }

      if (this.refreshCountdown <= 0)
      {
        if (!this.pause)
          this.$store.dispatch('getSpots');
        this.refreshCountdown = (this.spots && this.spots.length > 0) ? 60 : 120;
      }

      if (this.refreshHuntedCountdown <= 0)
      {
        this.$store.dispatch('getHunted');
        this.refreshHuntedCountdown = 600;
      }

      if (this.resetHuntedCountdown <= 0)
      {
        var utcDay = this.$dayjs.utc().format('YYYY-MM-DD');
        if (utcDay != this.currentUtcDay)
          this.$store.dispatch('resetHunted');
        this.resetHuntedCountdown = 10;
      }
    }
  },
  beforeDestroy() {
    if (this.refreshTimer != null) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.refresh-timer {
  padding-left: 20px;
  font-size: smaller;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>