<template>
  <v-layout row wrap>
    <!-- If no spots, display this -->
    <!--
    <v-flex xs12 sm6 lg4 v-if="activations.length == 0" class="text-xs-center">
      <div>There are no scheduled activations.</div>
      <div>Perhaps you should add your next activation!</div>
    </v-flex>
    -->

    <!-- Display card view for mobile browsers -->
    <v-flex xs12 sm6 lg4 v-for="item in activations" :key="item.scheduledActivitiesId" class="text-xs-left quiet-links">
      <v-card :height="card_height">
        <v-card-title primary-title class="title">
          <user-stats :callsign="item.activator"> &nbsp;&nbsp; @ &nbsp;&nbsp; {{ item.reference }}</user-stats>
        </v-card-title>

        <v-divider />
        <v-list dense>
          <v-list-item v-if="item.reference">
            <v-list-item-action><v-icon color="primary" small>{{$vuetify.icons.values.mdiMapMarker}}</v-icon></v-list-item-action>
            <v-list-item-content class="text-xs-left body-2">
              <span>
              <router-link :to="{ name: 'park', params: { id: item.reference } }">{{ item.reference }}&numsp;{{ item.name }}</router-link>
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.reference">
            <v-list-item-action><v-icon color="primary" small>{{$vuetify.icons.values.mdiCity}}</v-icon></v-list-item-action>
            <v-list-item-content class="text-xs-left body-2" v-if="item.locationDesc && item.locationDesc.includes(',')">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.locationDesc.split(',')[0].concat(', + ', (item.locationDesc.split(',').length - 1)) }}
                  </span>
                </template>
                <span>{{item.locationDesc }}</span>
              </v-tooltip>
            </v-list-item-content>
            <v-list-item-content class="text-xs-left body-2" v-else>{{ item.locationDesc }}</v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action><v-icon color="primary" small>{{$vuetify.icons.values.mdiVideoInputAntenna}}</v-icon></v-list-item-action>
            <v-list-item-content class="text-xs-left body-2">{{ item.frequencies || 'No frequency information' }}</v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action><v-icon color="primary" small>{{$vuetify.icons.values.mdiClockTimeFour}}</v-icon></v-list-item-action>
            <v-list-item-content class="text-xs-left body-2">{{ item.startDate }} {{ item.startTime }} to {{ item.endDate }} {{ item.endTime }} UTC</v-list-item-content>
          </v-list-item>
<!--
          <v-list-item two-line>
            <v-list-item-action><v-icon color="primary" small>speaker_notes</v-icon></v-list-item-action>
            <v-list-item-content class="text-xs-left py-0">{{ item.comments || 'No spot information' }}</v-list-item-content>
          </v-list-item>
-->
        </v-list>
        <v-divider />
        <div class="pa-1 text-xs-left body-2">{{ item.comments || 'No spot information' }}</div>
        <v-card-actions v-if="isSpotAdmin || item.schedulerUserId == authUserId">
        <v-spacer/>
        <activation-remove :activationId="item.scheduledActivitiesId" :userId="item.schedulerUserId" />
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex v-if="isAuthenticated" xs12 sm6 lg4>
      <activation-form :pactivator="callsign" card />
    </v-flex>
    <v-flex v-else xs12 class="text-xs-right">
      Will you be at a park later?
      <a @click="doSignIn"><b>Login to schedule your activation!</b></a>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.quiet-links a:link {
  color: inherit !important;
  text-decoration: none;
}
.quiet-links a:visited {
  color: inherit !important;
  text-decoration: none;
}
.quiet-links a:hover {
  color: inherit !important;
  text-decoration: underline dashed;
}
</style>

<script>
import { Auth } from 'aws-amplify'

import UserStats from '@/components/UserStats'
import ActivationRemove from '@/components/ActivationRemove'
import ActivationForm from '@/components/ActivationForm'

export default {
  components: {
    UserStats,
    ActivationRemove,
    ActivationForm
  },
  props: [
    'activations'
  ],
  computed: {
    isSpotAdmin: {
			get () {
				return this.$store.state.user.isSpotAdmin
      }
    },
    callsign: {
      get() {
        return this.$store.state.user.callsign
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    authUserId: {
      get() {
        return this.$store.state.user.userId
      }
    }
    //activations: state => state.activations.data,
    //loadingActivations: state => state.activations.loading
  },
  methods: {
    doSignIn: function () {
      Auth.federatedSignIn();
    },
  },
  data () {
    return {
      card_height: '20em'
    }
  }
}
</script>
<!--
<styles>
.full-height .flex {
  display flex
}

.full-height .flex > .card {
  flex 1 1 auto
}
</styles>
-->
