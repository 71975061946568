<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" max-height="500px">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn v-if="icon" text class="ma-2" color="success" v-on="{ ...tooltip, ...dialog }" @click="load"><v-icon>{{$vuetify.icons.values.mdiHistory}}</v-icon> {{ count }}</v-btn>
        <v-btn v-else outlined class="ma-2" color="success" v-on="{ ...tooltip, ...dialog }" @click="load"><v-icon dark>{{$vuetify.icons.values.mdiHistory}}</v-icon> {{ count }}</v-btn>
      </template>
      <span>History</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline green white--text" primary-title>
        <span>{{activator}} @ {{reference}}</span>
        <v-spacer/>
        <v-switch 
          v-model="showRbnInHistory"
          color="orange"
          label="RBN">
        </v-switch>
      </v-card-title>
        <v-list dense two-line v-if="comments">
          <template v-for="(item, index) in comments">
          <v-list-item :key="item.spotId" :class="getItemClass(item)" v-if="item.source != 'RBN' || showRbnInHistory">
              <v-list-item-content>
                <v-list-item-title v-if="item.source != 'RBN'">{{ item.spotter }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.mode }} on {{ item.band }} ({{item.frequency}} kHz) at {{ item.spotTime | time }} UTC via {{ item.source }}</v-list-item-subtitle>
                <v-list-item-content v-if="item.comments">{{ item.comments }}</v-list-item-content>
              </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < comments.length - 1 && (item.source != 'RBN' || showRbnInHistory)" :key="index"></v-divider>
        </template>
        <v-list-item v-if="!showRbnInHistory && countRbnSpots() > 0">
            <v-list-item-content>
              <v-list-item-subtitle>{{ countRbnSpots() }} RBN spots hidden</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </v-list>
        <v-list two-line v-else>
          <v-list-item>
              <v-list-item-content>
                <div class="text-center">
                  <v-progress-circular size="50" color="primary" indeterminate></v-progress-circular>
                </div>
              </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.row-new {
  background-color: var(--v-new-base) !important;
}
.row-dim {
  background-color: var(--v-dim-base) !important;
}
.row-normal {
  background-color: var(--v-normal-base) !important;
}
</style>

<script>
import axios from 'axios'

export default {
  name: 'SpotHistory',
  props: {
    activator: { required: true, default: '' },
    reference: { required: true, default: '' },
    icon: { default: false, type: Boolean },
    count: { required: true, default: 0 }
  },
  data: function () {
    return {
      dialog: false,
      loading: true,
      comments: null
    }
  },
  computed: {
    showRbnInHistory: {
      get () {
        return this.$store.state.config.showRbnInHistory
      },
      set (value) {
        this.$store.commit('SET_SHOW_RBN', value);
      }
    },
  },
  methods: {
    load () {
      this.loading = true
      this.comments = null

      var sanitizedCallsign = encodeURIComponent(this.activator);

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/spot/comments/${sanitizedCallsign}/${this.reference}`)
        .then(response => response.data)
        .then(json => this.comments = json)
        .then(() => this.loading = false)
    },
    close () {
      this.dialog = false
    },
    getItemClass(item) {
      if (item.spotter == this.activator)
        return 'row-new';
      if (item.source == 'RBN')
        return 'row-dim';
      return 'row-normal';
    },
    countRbnSpots() {
      if (!this.comments)
        return 0;
      return this.comments.filter(x => x.source == 'RBN').length;
    }
  }
}
</script>
