<template>
  <v-card flat class="hidden-xs-only quiet-links">
    <v-data-table :headers="headers" hide-default-footer disable-pagination disable-sort :items="spots" item-key="spotId">
      <!-- <v-progress-linear slot="progress" color="green lighten-3" indeterminate></v-progress-linear> -->

      <template v-slot:item="{ item }">
        <tr :style="getItemStyle(item)">
          <td class="text-xs-left px-1 text-no-wrap">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.date | dateTimeCard }}
                </span>
              </template>
              <span>{{ item.date | time }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs-left px-1 text-no-wrap"><user-stats :callsign="item.activator"></user-stats></td>
          <td class="text-xs-left px-1 text-no-wrap">{{ item.frequency }} <span v-if="item.mode && item.mode != 'SSB'">({{ item.mode }})</span></td>
          <!--<td class="text-xs-left px-1 text-no-wrap"><router-link :to="{ name: 'park', params: { id: item.reference } }">{{ item.reference }}</router-link></td>-->
          <td class="text-xs-left px-1 text-no-wrap width20 ellipsis">
            <span>
              <template v-if="isNewPark(item)">◈&numsp;</template>
              <router-link :to="{ name: 'park', params: { id: item.reference } }">{{ item.reference }}&numsp;{{ item.name }}</router-link>
            </span>
          </td>
          <td class="text-xs-left px-1 text-no-wrap" v-if="item.locationDesc.includes(',')">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.locationDesc.split(',')[0].concat(', + ', (item.locationDesc.split(',').length - 1)) }}
                </span>
              </template>
              <span>{{item.locationDesc }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs-left px-1 text-no-wrap" v-else>
            <template v-if="isNewLocation(item)">◈&numsp;</template>{{ item.locationDesc }}
          </td>
          <td class="text-xs-left px-1 text-no-wrap"><user-stats :callsign="item.spotter" :rbn="item.rbn"></user-stats></td>
          <td class="text-xs-left px-1 text-no-wrap width25 ellipsis"><span>{{ item.comments }}</span></td>
          <td class="text-left px-0 min">
            <spot-history :activator="item.activator" :reference="item.reference" :count="item.count" icon />
          </td>
          <td class="text-right px-0 min">
            <spot-worked :worked="item.hunted" :activator="item.activator" :reference="item.reference" :band="item.band" :mode="item.mode" />
            <spot-remove :spotId="item.spotId" icon />
            <spot-form v-if="isAuthenticated || !isAuthenticated"
              title="Re-Spot"
              buttoncolor="primary"
              icon
              :buttonicon="$vuetify.icons.values.mdiAutorenew"
              :spot="item"
            />
          </td>
        </tr>
      </template>

      <template v-slot:foot>
        <tfoot>
          <tr>
            <td colspan="9" style="text-align: right">
              At a park now?
              <a v-if="!isAuthenticated" @click="doSignIn"><b>Login to add your spot!</b></a>
              <spot-form v-else :pspotter="callsign" requireComment></spot-form>
            </td>
          </tr>
        </tfoot>
      </template>

      <template v-slot:no-data>
        <div class="text-subtitle-1">No one has been spotted in the last 30 minutes.</div>
        <div class="text-subtitle-2">Perhaps you should go activate a park!</div>
      </template>

      <template v-slot:no-results>
        <div class="text-subtitle-1">No one has been spotted in the last 30 minutes.</div>
        <div class="text-subtitle-2">Perhaps you should go activate a park!</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
.row-dim {
  background-color: var(--v-dim-base) !important;
}
.row-highlight {
  background-color: var(--v-highlight-base) !important;
}
.row-new {
  background-color: var(--v-new-base) !important;
}
.row-normal {
  background-color: var(--v-normal-base) !important;
}
</style>

<script>
import SpotForm from '@/components/SpotForm'
import SpotHistory from '@/components/SpotHistory'
import SpotWorked from '@/components/SpotWorked'
import SpotRemove from '@/components/SpotRemove'
import UserStats from '@/components/UserStats'

import { Auth } from 'aws-amplify'

export default {
  computed: {
		spots: {
			get () {
				return this.$store.state.spots.filtered
			}
    },
    sort: {
      get() {
        return this.$store.state.spots.sort
      }
    },
    callsign: {
			get () {
				return this.$store.state.user.callsign
			}
    },
    hunted: {
      get () {
        return this.$store.state.user.hunted
      }
    },
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    isSpotAdmin: {
			get () {
				return this.$store.state.user.isSpotAdmin
      }
    },
    loadingSpots: {
      get() {
        return this.$store.state.spots.loading
      }
    },
    highlightNewSpots: {
      get () {
        return this.$store.state.config.highlightNewSpots
      }
    },
  },
  components: {
    SpotForm,
    SpotHistory,
    SpotWorked,
    UserStats,
    SpotRemove
  },
  methods: {
    doSignIn: function () {
      Auth.federatedSignIn();
    },
    getItemClass(item) {
      console.log(item);
      if (item.user)
        return 'row-highlight';
      if (item.hunted || item.qrt)
        return 'row-dim';
      if (this.highlightNewSpots && this.sort != 'Time' && item.age < 60)
        return 'row-new';
      return '';
    },
    getItemStyle(item) {
      var color = null;
      if (item.user)
        color = this.$vuetify.theme.currentTheme.highlight;
      else if (item.hunted || item.qrt)
        color = this.$vuetify.theme.currentTheme.dim;
      else if (this.highlightNewSpots && this.sort != 'Time' && item.age < 60)
        color = this.$vuetify.theme.currentTheme.new;

      if (color)
        return `background-color: ${color} !important`;
      return '';
    },
    isNewPark(item) {
      return item.spotId == 0;
    },
    isNewLocation(item) {
      return item.spotId == 0;
    },
  },
  data () {
    return {
      headers: [
        { text: 'Time', value: 'spotTime', align: 'left', sortable: true, class: 'text-xs-left px-1' },
        { text: 'Activator', value: 'activator', align: 'left', sortable: true, class: 'text-xs-left px-1' },
        { text: 'Frequency', value: 'frequency', align: 'left', sortable: true, class: 'text-xs-left px-1' },
        /*{ text: 'Reference', value: 'reference', align: 'left', sortable: true, class: 'text-xs-left px-1' },*/
        { text: 'Park', value: 'name', align: 'left', sortable: true, class: 'text-xs-left px-1' },
        { text: 'Region', value: 'locationDesc', align: 'left', sortable: true, class: 'text-xs-left px-1' },
        { text: 'Spotter', value: 'spotter', align: 'left', sortable: false, class: 'text-xs-left px-1' },
        { text: 'Comments', value: 'comments', align: 'left', sortable: false, class: 'text-xs-left px-1' },
        { text: '', value: '', align: 'left', sortable: false, class: 'text-xs-right px-1' },
        { text: '', value: '', align: 'right', sortable: false, class: 'text-xs-right px-1' },
      ]
    }
  }
}
</script>
