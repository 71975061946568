<template>
  <v-dialog v-if="isSpotAdmin || authUserId == userId" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="icon" v-on="on" text class="ma-2" color="error"><v-icon dark>{{$vuetify.icons.values.mdiClose}}</v-icon></v-btn>
      <v-btn v-else v-on="on" x-small outlined class="ma-2" color="error">Remove <v-icon dark>{{$vuetify.icons.values.mdiClose}}</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-title class="headline green white--text" primary-title>
        <span>Confirm Remove Activation</span>
      </v-card-title>
      <v-card-text>
        <div class="text-h6">Are you sure that you want to remove this activation? [{{activationId}}]</div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="confirm">Yes</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ActivationRemove',
  props: {
    activationId: { default: 0 },
    userId: { default: -1 },
    icon: { default: false, type: Boolean }
  },
  data: function () {
    return {
      dialog: false
    }
  },
  computed: {
    isSpotAdmin: {
			get () {
				return this.$store.state.user.isSpotAdmin
      }
    },
    authUserId: {
      get() {
        return this.$store.state.user.userId
      }
    }
  },
  methods: {
    confirm () {
      // Native form submission is not yet supported
      const formData = {
        activationId: this.activationId
      }

      axios
        .post(`https://${process.env.VUE_APP_API_URL}/activation/remove`, formData, this.$store.getters.authTokenHeader)
        .then((response) => {
              this.$store.commit('SET_ACTIVATIONS', response.data) // update activations with POST response data
            })

      this.dialog = false
    },
    close () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
</style>
